import { SelectionChangedEvent } from "ag-grid-community";
import { CommonAccountCompanyEntity } from "domain/entity/CommonAccount/CommonAccountCompanyEntity";
import { INITIAL_COMMON_ACCOUNT_COMPANY_COL_DEF } from "presentation/constant/CommonAccount/CommonAccountCompanyColumnDefinition";
import { CommonAccountConstant } from "presentation/constant/CommonAccount/CommonAccountConstant";
import { useCommonAccountVM } from "presentation/hook/CommonAccount/useCommonAccountVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { CommonAccountModel } from "presentation/model/CommonAccount/CommonAccountModel";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CommonAccountCompanyEditPanel from "../RightPanel/CommonAccountCompanyEditPanel";
const CommonAccountCompanyTablePanel = ({ commonAccountState }: { commonAccountState: CommonAccountModel }) => {
    const gridRef: any = useRef(null);
    const { tempCompanyRows, selectedCompanyRows, isShowCompanyAddPanel, isShowCompanyEditPanel, isEdit, isAdd } = commonAccountState;
    const commonAccountVM = useCommonAccountVM();
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        if(isAdd) return;
        
        const selectedRows = e.api.getSelectedRows();
        commonAccountVM.updateSelectedCompanyRows(selectedRows);
    }, [commonAccountVM, isAdd])


    const handleRowDoubleClick = useCallback((entity: CommonAccountCompanyEntity) => {
        if (!isEdit && !isAdd) return;
        commonAccountVM.onCompanyRowDoubleClick(entity);
    }, [commonAccountVM, isAdd, isEdit])

    const handleAddClick = useCallback(() => {

        commonAccountVM.onCompanyAddRowClick();
    }, [commonAccountVM])

    const handDelete = useCallback(() => {
        if (selectedCompanyRows.length === 0) {
            messageBarVM.showError("Please select a record.");
        }
        commonAccountVM.onCompanyDtlDeleteClick(selectedCompanyRows);
    }, [commonAccountVM, messageBarVM, selectedCompanyRows]);

    const memoBtns = useMemo(() => {
        const isDisabled = !((isAdd || isEdit) && !(isShowCompanyAddPanel || isShowCompanyEditPanel));

        const hdrBtns = [];
        /*((isAdd || isEdit) && !(isShowCompanyAddPanel || isShowCompanyEditPanel)) && hdrBtns.push({
            id: 'onDeleteButton',
            icon: 'Icon-trash',
            title: 'Delete'
        })*/

        hdrBtns.push({
            id: 'onAddButton',
            icon: 'Icon-add',
            title: 'Add',
            disable: isDisabled
        })

        hdrBtns.push({
            id: 'onDeleteButton',
            icon: 'Icon-trash',
            title: 'Delete',
            disable: isDisabled
        })

        return hdrBtns
    }, [isAdd, isEdit, isShowCompanyAddPanel, isShowCompanyEditPanel])

    const isRowSelectable = useCallback((params: any) => {
        if (isShowCompanyEditPanel || isShowCompanyAddPanel) {
            return false;            
        } else {
            return true;            
        }
    }, [isShowCompanyAddPanel, isShowCompanyEditPanel]);

    const memoCommonAccountCompanyTable = useMemo(() => {

        return (
            <TableWrapper>
                <NbisTable
                    id='common-account-company-table'
                    isNewColumnSetting={true}
                    headerLabel={CommonAccountConstant.Title.COMPANY_TITLE}
                    columns={INITIAL_COMMON_ACCOUNT_COMPANY_COL_DEF}
                    data={tempCompanyRows ?? []}
                    showPaginator={false}
                    editable={false}
                    headerActionButtons={memoBtns}
                    onAddButton={handleAddClick}
                    onDeleteButton={handDelete}
                    showAddIcon={false/*((isAdd || isEdit) && !(isShowCompanyAddPanel || isShowCompanyEditPanel))*/}
                    //onAddClick={handleAddClick}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    showUploadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={true}
                    isRowHighligted={true}
                    rowSelection={"multiple"}
                    handleSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any, entity: CommonAccountCompanyEntity) => handleRowDoubleClick(entity)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 75px)"
                    ref={gridRef}
                    handleRowSelectable={isRowSelectable}
                /></TableWrapper>
        );
    }, [tempCompanyRows, memoBtns, handDelete, handleAddClick, handleSelectionChange, isRowSelectable, handleRowDoubleClick])

    useEffect(() => {
            if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
                if (isShowCompanyAddPanel) {
                    gridRef.current?.gridRef.current.api?.deselectAll();
                }
                gridRef.current.gridRef.current.api.redrawRows();
            }
        }, [isAdd, isEdit, isShowCompanyAddPanel, isShowCompanyEditPanel, tempCompanyRows])
    // return <><TableWrapper>
    //         {memoCommonAccountCompanyTable}
    //     </TableWrapper>
    //     </>;

    return <>
        <div className={`main-comp-wrapper${(isShowCompanyAddPanel || isShowCompanyEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={(isShowCompanyAddPanel || isShowCompanyEditPanel) ? "40%" : "100%"}
                rightSectionWidth={(isShowCompanyAddPanel || isShowCompanyEditPanel) ? "60%" : "0%"}
                leftChildren={memoCommonAccountCompanyTable}
                rightChildren={<CommonAccountCompanyEditPanel />}
            />
        </div>
    </>
}

export default memo(CommonAccountCompanyTablePanel);
